import React, { Component } from 'react'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import Layout from 'Components/Layout'
import SeoHead from 'Components/SeoHead'
import Breadcrumbs from 'Components/UI/Breadcrumbs'
import SearchLayer from 'Components/UI/SearchLayer'

class SearchPage extends Component {
  constructor(props) {
    super(props)
    this.contact = React.createRef()
    this.breadcrumbsData = [
      {
        path: `${this.props.location.pathname}`,
        title: 'Search'
      }
    ]
  }
  componentDidMount() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentDidUpdate() {
    setTimeout(AppActions.finishLoadingPage, AppConstants.LOADING_PAGE_TIMEOUT)
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <Layout>
        <SeoHead
          title='Search'
          path={this.props.location.pathname}
        />
        <Breadcrumbs items={this.breadcrumbsData}/>
        <div className={``}>
          <div className='u-height--60' />
          <SearchLayer isActive={true} />
        </div>
      </Layout>
    )
  }
}

export default SearchPage

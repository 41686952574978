import React from 'react'
import Link from 'Components/Utils/Link'
import styles from './index.module.scss'

const Breadcrumbs = (props) => (
  <div className={`${styles.parent} u-row`}>
    <div className={`u-col-md-10 u-offset-md-1 u-vertical-center-parent`}>
      <div className={`${styles.holder} u-vertical-center-child`}>
        { breadcrumbItem('/', 'home', 'breadcrumbs-item-home', true) }
        { props.items.map((item, index) => breadcrumbItem(item.path, item.title, `breadcrumbs-item-${index}`, index !== props.items.length - 1, item.disable)) }
      </div>
    </div>
  </div>
)

export const breadcrumbItem = (link, name, key, lastItem=true, disable=false) => {
  const titleLink = disable ? <span className={`t-color-black`} dangerouslySetInnerHTML={{__html: name}}></span> : <Link className={`t-color-black`} to={link} dangerouslySetInnerHTML={{__html: name}}></Link>
  return (
    <span className={`t-title-1 u-uppercase`} key={key}>
      {titleLink}
      {lastItem && <span className={`${styles.arrowIcon} u-inline-block u-pad-x--small`}>
        <svg className="u-fit t-fill-color-black">
          <use xlinkHref="#arrow-breadcrumb" />
        </svg>
      </span>}
    </span>
  )
}

export default Breadcrumbs
